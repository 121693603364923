import React, { useEffect, useState } from "react";
import { FaCalendar, FaUserGroup, FaBook, FaHandshakeSimple, FaChevronDown } from "react-icons/fa6";

export const gutterStyles = "max-w-[99%] sm:max-w-[97%] md:max-w-[95%] lg:max-w-[90%] xl:max-w-[80%] 2xl:max-w-[1280px] w-full";

export const ReactiveGutter = ({ children }: { children: React.ReactNode }) => {
  return <div className={`flex flex-row items-center justify-center ${gutterStyles}`}>{children}</div>;
};

export const Panel = ({ children, marginY = 0, className = "" }: { children: React.ReactNode; marginY?: number; className?: string }) => {
  return <div className={`flex flex-col items-center justify-center dark:bg-gray-800 my-${marginY} min-h-1 ${className}`}>{children}</div>;
};

//fullscreen panel with a text button at the bottom that scrolls screen
export const FullScreenPanel = ({
  children,
  marginY = 0,
  className = "min-h-[100vh]",
  hideChevron = false,
}: {
  children: React.ReactNode;
  marginY?: number;
  className?: string;
  hideChevron?: boolean;
}) => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const handleScroll = () => {
    const position = window.scrollY;
    setScrollPosition(position);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <Panel marginY={marginY} className={`${className}`}>
        {children}
        <div className={`absolute bottom-0 w-full grid place-content-center py-4 text-3xl ${hideChevron || scrollPosition > 400 ? "hidden" : ""}`}>
          <a href="#target" className="text-primary dark:text-background-nav">
            <FaChevronDown />
          </a>
        </div>
      </Panel>
      <span id="target"></span>
    </>
  );
};

export const ReactiveGrid = ({ children, className }: { children: React.ReactNode; className: string }) => {
  return <div className={`flex flex-row items-center justify-evenly w-full ${className}`}>{children}</div>;
};

export const getIconFromName = (iconName: string) => {
  const iconProps = {
    size: 110,
  };

  switch (iconName) {
    case "Calendar":
      return <FaCalendar {...iconProps} />;
    case "UserGroup":
      return <FaUserGroup {...iconProps} />;
    case "Book":
      return <FaBook {...iconProps} />;
    case "HandsHelping":
      return <FaHandshakeSimple {...iconProps} />;
    default:
      return <FaCalendar {...iconProps} />;
  }
};

export const ActionBoxItem = ({ Title, Icon }: { Title: string; Icon: string }) => {
  const IconComponent = getIconFromName(Icon);
  return (
    <a
      href="#"
      className={`flex flex-col items-center justify-center w-36 h-36 rounded-3xl group transition ease-in-out bg-background dark:bg-background-nav text-primary dark:text-text-dark duration-500`}>
      <div className="flex flex-col items-center justify-center absolute opacity-100 group-hover:opacity-20 transition-all ease-in-out">
        {IconComponent}
      </div>
      <div className="flex flex-col items-center justify-center z-10 p-4 text-xl font-bold opacity-0 group-hover:opacity-100 transition-all ease-in-out text-center">
        {Title}
      </div>
    </a>
  );
};

export const ActionBox = () => {
  return (
    <ReactiveGrid className={"py-8"}>
      <ActionBoxItem Title="View Calendar" Icon="Calendar" />
      <ActionBoxItem Title="Cadet 365" Icon="UserGroup" />
      <ActionBoxItem Title="Training Resources" Icon="Book" />
      <ActionBoxItem Title="Join" Icon="HandsHelping" />
    </ReactiveGrid>
  );
};
