import React, { useEffect, useState } from "react";
import { useTranslation, Trans } from "react-i18next";
import { Footer } from "../Footer";
import { Header } from "../Header";
import { ThemeToggle } from "../ThemeToggle";
import { LocaleSwitcher } from "../LocaleSwitcher";
import { Link, useLocation } from "react-router-dom";
import { StaticHeader } from "../StaticHeader";

export const primaryGutter = "max-w-[95%] lg:max-w-[90%] xl:max-w-[80%] 2xl:max-w-[1280px] w-full";

export const NavBarSpacer = () => {
  return <div className="h-48" />;
};

export const WebLayout = ({ children }: { children: React.ReactNode }) => {
  const { t } = useTranslation();
  const location = useLocation();

  const menu = [
    {
      label: t("menu.home"),
      href: "/",
    },
    {
      label: t("menu.about"),
      href: "/about",
    },
    {
      label: t("menu.join"),
      href: "/join",
    },
  ];

  const MenuItem = ({ children, active = false }: { children: React.ReactNode; active?: boolean }) => {
    return (
      <li
        className={`py-1 px-2  ${
          active ? " text-accent outline-text font-medium" : "hover:bg-[rgba(100,100,100,0.1)] text-text-white"
        }  grid place-content-center`}>
        {children}
      </li>
    );
  };

  const MenuLink = ({ label, href, active, autoDarken = false }: { label: string; href: string; active: boolean; autoDarken?: boolean }) => {
    const [scrollPosition, setScrollPosition] = useState(100);
    const handleScroll = () => {
      //const position = window.scrollY;
      //setScrollPosition(position);
    };

    useEffect(() => {
      if (autoDarken) {
        setTimeout(() => {
          //setScrollPosition(1000);
        }, 2000);
      } else {
        //window.addEventListener("scroll", handleScroll, { passive: true });
      }

      return () => {
        //window.removeEventListener("scroll", handleScroll);
      };
    }, [autoDarken]);

    return (
      <Link aria-current="page" to={href} className={`${scrollPosition == 0 ? "opacity-25" : ""} transition-opacity duration-300`}>
        <MenuItem active={active}>{label}</MenuItem>
      </Link>
    );
  };
  console.log(location.pathname === "/");

  return (
    <>
      <StaticHeader autoDarken={location.pathname === "/" || location.pathname === "/join"}>
        <>
          <ul className="flex flex-row gap-1">
            {menu.map((item, index) => (
              <MenuLink
                autoDarken={location.pathname === "/" || location.pathname === "/join"}
                key={index}
                label={item.label}
                href={item.href}
                active={location.pathname === item.href}
              />
            ))}
            <MenuItem>
              <ThemeToggle />
            </MenuItem>
          </ul>
        </>
      </StaticHeader>
      <div className="min-h-[100vh] flex flex-col overflow-hidden">
        <div className={`min-h-[calc(100vh-52px)] ${location.pathname === "/" ? "max-h-[calc(100vh-52px)]" : ""} overflow-hidden`}>{children}</div>
        <Footer></Footer>
      </div>
    </>
  );
};
