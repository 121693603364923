import React, { useEffect } from "react";
import { SUPPORTED_LANGUAGES } from "../../../i18n";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../library/Dropdown";
import { FaGlobe } from "react-icons/fa6";
import { useState } from "react";
import { primaryGutter } from "../layouts/WebLayout";
import { use } from "i18next";
import { Carousel } from "react-responsive-carousel";
import { Link } from "react-router-dom";

const left = "left-[2.5%] lg:left-[5%] xl:left-[10%] w-[47.5%] lg:w-[45%] xl:w-[40%]";

export const JoinBanner = () => {
  const { t, i18n } = useTranslation("", { keyPrefix: "joinPage" });
  const current_lang = i18n.resolvedLanguage;

  const imageGallery = [
    {
      image: "LocalAssets/SquadronGroupPhoto.jpg",
      alt: "Group Photo",
      css: "max-w-max w-[200%] grid place-self-center",
    },
    {
      image: "LocalAssets/Join101.png",
      alt: "Joining 101",
      css: "relative -top-[100px]",
    },
    {
      image: "LocalAssets/Boots1.jpg",
      alt: "Boots",
      css: "max-w-max w-[200%] grid place-self-center",
    },
    {
      image: "LocalAssets/WO1.png",
      alt: "WO1",
      css: "max-w-max w-[200%] grid place-self-center",
    },
    {
      image: "LocalAssets/WO2s.png",
      alt: "WO2",
      css: "max-w-max w-[150%] grid place-self-center",
    },
  ];

  const ANIMATE = true;
  const [hovering, setHovering] = useState(false);
  const [wasHovering, setWasHovering] = useState(false);
  const [image, setImage] = useState(imageGallery[0]);
  const [index, setIndex] = useState(5);
  const [delta, setDelta] = useState(0);
  const [lastSwap, setLastSwap] = useState(Date.now());

  useEffect(() => {
    setImage(imageGallery[index]);
  }, [setImage, index]);

  useEffect(() => {
    if (!ANIMATE) return;

    if (!hovering) {
      const now = Date.now();

      if (delta > 2000) {
        setLastSwap(now);
        setIndex((index + 1) % imageGallery.length);
      }
    } else {
      setLastSwap(Date.now());
    }
  }, [hovering, delta, setDelta, setLastSwap, setIndex]);

  useEffect(() => {
    if (!ANIMATE) return;

    if (!hovering && wasHovering) {
      setWasHovering(false);
      setLastSwap(Date.now());
    }
    if (hovering) {
      setWasHovering(true);
    }
  }, [hovering, wasHovering, setWasHovering, setLastSwap]);

  useEffect(() => {
    if (!ANIMATE) return;

    if (!hovering) {
      const interval = setInterval(() => {
        setDelta(Date.now() - lastSwap);
      }, 1000);
      return () => clearInterval(interval);
    } else {
      setDelta(0);
    }
  }, [hovering, lastSwap]);

  return (
    <div className={`${primaryGutter} flex flex-col lg:flex-row justify-end py-16`}>
      <div
        id="image-gallery-frame"
        className={`relative w-[80vw] lg:w-5/12 self-center rounded-[32px] m-2 ml-8 mr-8 grid place-content-center h-[450px] overflow-hidden`}
        onMouseOver={() => {
          setHovering(true);
        }}
        onMouseOut={() => {
          setHovering(false);
        }}>
        {imageGallery.map((img, i) => (
          <img
            key={i}
            src={img.image}
            alt={img.alt}
            className={`absolute object-contain h-full ${img.css} transform ${hovering ? "scale-105 cursor-pointer" : ""}  ${
              i === index ? "opacity-100" : "opacity-0"
            }  transition-all duration-500 `}
          />
        ))}
      </div>
      <div id="join-form" className="lg:w-7/12 text-text dark:text-text-dark grid lg:place-content-center">
        <div className="md:ml-2 max-w-[90vw]">
          <div className="text-center lg:text-left text-2xl md:text-5xl font-black pb-4">{t("title")}</div>
          <ul className="grid gap-2 font-normal">
            <li>
              <b>{`${t("step")} 1) `}</b>
              {t("steps.0")}
            </li>
            <li>
              <b>{`${t("step")} 2) `}</b>
              {t("steps.1")}
            </li>
            <li>
              <b>{`${t("step")} 3) `}</b>
              {t("steps.2")}
            </li>
            <li>
              <b>{`${t("step")} 4) `}</b>
              {t("steps.3")}
            </li>
          </ul>
        </div>
        <div id="join-form-button" className="grid place-content-center max-w-[90vw]">
          <Link to={"https://registration.cadets.gc.ca/get-started.html?unitId=460"} target="_blank">
            <button className="bg-accent dark:bg-accent-dark text-text-white dark:text-text-white  text-2xl font-bold w-[80vw] lg:w-full px-24 xs:px-52 py-2 mt-4 whitespace-nowrap grid place-content-center hover:bg-accent-dark dark:hover:bg-accent transition-all duration-300">
              {t("button")}
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};
