import React, { useState, useEffect } from "react";
import { GroupedTimeline } from "../flowbite-raw/GroupedTimeline";

const getEventsUrl = process.env.REACT_APP_API_BASE + "calendar";

export const CalendarComponent = () => {
  const [events, setEvents] = useState([]);

  const getEvents = async () => {
    try {
      return await fetch(getEventsUrl, {
        //https://101-moncton-web-api.vercel.app-/api/calendar`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "access-control-allow-origin": "*",
        },
      });
    } catch (error) {
      console.error("Error during token check", error);
    }
  };

  useEffect(() => {
    getEvents().then((response) => {
      if (response && response.ok) {
        response.json().then((data) => {
          const groupedEvents = {};
          data.forEach((event) => {
            const date = event.start.dateTime.split("T")[0];
            if (!groupedEvents[date]) {
              groupedEvents[date] = [];
            }
            groupedEvents[date].push(event);
          });

          // Transform groupedEvents into the desired format
          const formattedEvents = Object.keys(groupedEvents).map((date) => ({
            date,
            events: groupedEvents[date],
          }));

          setEvents(formattedEvents.slice(0, 10));
        });
      }
    });
  }, []);

  return (
    <div className="w-full">
      <div className="text-2xl font-bold text-gray-800 dark:text-gray-100 pl-4 py-2">Upcoming Events</div>
      <GroupedTimeline data={events} />
    </div>
  );
};
