import { useEffect, useState } from "react";
import { ThemeToggle } from "./ThemeToggle";
import React, { useRef } from "react";
import { NavBarSpacer, primaryGutter } from "./layouts/WebLayout";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { LocaleSwitcher } from "./LocaleSwitcher";

const SquadronLogo = ({ onClick, scrollPosition }) => {
  const { t, i18n } = useTranslation("", { keyPrefix: "menu" });

  return (
    <a className="flex flex-row md:grid place-content-center h-full items-center" href="/">
      <img onClick={onClick} src="101MonctonHighDef.png" className={`hidden md:block fixed hover:cursor-pointer h-14 md:h-40 md:mb-3`} />
      <img onClick={onClick} src="101MonctonHighDef.png" className={`relative top-[50%] translate-y-[-60%] md:hidden hover:cursor-pointer h-14`} />
      <div
        className={`grid place-content-center font-bold text-l 2xs:text-xl xs:text-2xl text-text-white ml-2 md:ml-36 ${
          scrollPosition < 60 ? "opacity-0" : "opacity-100"
        } transition-all duration-[2500ms]`}>
        101 Moncton {t("rcacs")}
      </div>
    </a>
  );
};

const RawMobileMenu = ({ isOpen, children, onClick }) => {
  return (
    <div
      id="dropdownDots"
      class={`z-10 opacity-0 ${
        isOpen ? "opacity-100" : "top-[-1000px]"
      } fixed right-8 top-[52px] bg-background-nav border-accent border-solid border drop-shadow-xl divide-y divide-gray-100 rounded-lg shadow w-32 dark:bg-gray-700 dark:divide-gray-600 transition-opacity duration-300 `}>
      <ul class="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownMenuIconButton" onClick={onClick}>
        {children}
      </ul>
    </div>
  );
};

function useOutsideAlerter(ref) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setIsOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);

  return {
    isOpen,
    setIsOpen,
  };
}

const MobileMenuButton = ({ children }) => {
  const wrapperRef = useRef(null);
  const { isOpen, setIsOpen } = useOutsideAlerter(wrapperRef);

  return (
    <div ref={wrapperRef}>
      <button
        data-collapse-toggle="navbar-default"
        type="button"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
        className="p-3 my-3 md:hidden text-text-dark"
        aria-controls="navbar-default"
        aria-expanded="false">
        <span className="sr-only">Open main menu</span>
        <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 17 14">
          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M1 1h15M1 7h15M1 13h15" />
        </svg>
      </button>
      <RawMobileMenu onClick={() => setIsOpen(false)} isOpen={isOpen}>
        {children}
      </RawMobileMenu>
    </div>
  );
};

export const StaticHeader = ({ children, autoDarken = false }) => {
  const [scrollPosition, setScrollPosition] = useState(100);
  const handleScroll = () => {
    const position = window.scrollY;
    //setScrollPosition(position);
  };

  useEffect(() => {
    if (autoDarken) {
      setTimeout(() => {
        setScrollPosition(1000);
      }, 2000);
    } else {
      //setScrollPosition(window.scrollY);
      window.addEventListener("scroll", handleScroll, { passive: true });
    }

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [autoDarken]);

  const MenuItem = ({ children, active = false }) => {
    return (
      <li
        className={`my-3 sm:px-2  sm:min-w-[2rem] sm:mx-1 ${
          active ? " text-accent font-medium" : "hover:bg-[rgba(100,100,100,0.1)] text-text-white"
        }  grid place-content-center`}>
        {children}
      </li>
    );
  };

  return (
    <>
      <div
        className={`fixed w-[100vw] bg-background dark:bg-background-dark h-0 xs:h-[5rem] ${
          scrollPosition > 60 ? "bg-opacity-0" : "bg-opacity-80"
        } transition-all duration-700`}></div>
      <nav
        className={`fixed w-full bg-white border-accent bg-background-dark md:mt-20 border-b-[1px]  ${
          scrollPosition < 60 ? "bg-opacity-25" : ""
        } transition-all duration-700 z-50 flex flex-row justify-center translate-x-0`}>
        <div className="w-full bg-primary bg-opacity-25 flex justify-center">
          <div className={`${primaryGutter} bg-opacity-0 flex justify-between`}>
            <SquadronLogo onClick={() => {}} scrollPosition={scrollPosition} />
            <div className="flex flex-row justify-end">
              <ul className="w-full hidden md:flex flex-row py-3">{children}</ul>
              <MenuItem>
                <LocaleSwitcher />
              </MenuItem>
              <MobileMenuButton>{children}</MobileMenuButton>
            </div>
          </div>
        </div>
      </nav>
    </>
  );
};
