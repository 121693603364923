import React from "react";
import { useTranslation } from "react-i18next";
import { FaFacebook, FaMicrosoft, FaSquareXTwitter } from "react-icons/fa6";

const Divider = ({ className }) => {
  return <hr className={`my-3 sm:mx-auto lg:my-4 border-accent ${className}`} />;
};

const Copyright = () => {
  const { t } = useTranslation();
  return (
    <span className={`text-[0.5rem] sm:text-xs md:text-sm text-gray-500 sm:text-center dark:text-gray-400`}>
      ©{new Date().getFullYear()}{" "}
      <a href="https://www.facebook.com/101MonctonRCACS" className="hover:underline">
        101 Moncton {t("footer.copyright.rcacs")}
      </a>
      . {t("footer.copyright.allRightsReserved")}.
    </span>
  );
};

const SocialLink = ({ href, icon, srText }) => {
  return (
    <a href={href} className="text-gray-500 hover:text-gray-900 dark:hover:text-white ms-5">
      {icon}
      <span className="sr-only">{srText}</span>
    </a>
  );
};

const Newsletter = () => {
  const { t } = useTranslation();
  return (
    <div className="md:mb-0 flex flex-row max-w-[400px] justify-end">
      <a href="/about" className="flex min-w-[160px] mr-4 my-2">
        <img src="101MonctonHighDef.png" className="me-3 h-48" alt="101 Moncton Logo" />
      </a>
      <div className="flex flex-col">
        <span className="self-right text-left text-2xl font-semibold whitespace-nowrap dark:text-white">101 {t("footer.newsletter.rcacs")}</span>
        <span className="self-right text-left text-sm text-gray-500 dark:text-gray-400">Moncton, {t("footer.newsletter.nb")}</span>
        <span className="self-right text-left text-sm text-gray-500 dark:text-gray-400 pt-3">{t("footer.newsletter.subscribeToOurNewsletter")}</span>
        <input
          type="text"
          name="email"
          id="email"
          className="w-full p-1 pl-2 mt-2 bg-gray-100 border-none dark:bg-gray-800 bg-background-dark"
          placeholder={t("footer.newsletter.emailPlaceholder")}
        />
        <button className="w-full p-1 mt-2 text-white bg-primary ">{t("footer.newsletter.subscribe")}</button>
      </div>
    </div>
  );
};

const FooterLinkList = ({ title, links }) => {
  return (
    <div className="col-span-1 mt-4">
      <h2 className="mb-3 text-sm font-semibold text-gray-900 uppercase dark:text-white">{title}</h2>
      <ul className="text-gray-500 dark:text-gray-400 font-medium">
        {links.map((link) => (
          <li className="mb-1">
            <a href={link.href} className="hover:underline text-[gray] font-normal">
              {link.title}
            </a>
          </li>
        ))}
      </ul>
    </div>
  );
};

const FooterLinks = (props) => {
  return (
    <div className={`grid grid-cols-2 sm:grid-cols-4 w-full max-w-[720px]`}>
      {props.footerLinks.map((footerLinkList) => (
        <FooterLinkList title={footerLinkList.title} links={footerLinkList.links} />
      ))}
    </div>
  );
};

export const Footer = () => {
  const { t } = useTranslation();
  const footerLinks = [
    {
      title: t("footer.links.aboutTitle"),
      links: [
        {
          title: t("footer.links.whoWeAre"),
          href: "/about",
        },
        {
          title: t("footer.links.history"),
          href: "/history",
        },
        {
          title: t("footer.links.mission"),
          href: "/mission",
        },
        {
          title: t("footer.links.contact"),
          href: "/contact",
        },
        {
          title: t("footer.links.privacy"),
          href: "/privacy",
        },
      ],
    },
    {
      title: t("footer.links.joinTitle"),
      links: [
        {
          title: t("footer.links.join"),
          href: "/join",
        },
        {
          title: t("footer.links.benefits"),
          href: "/benefits",
        },
        {
          title: t("footer.links.faq"),
          href: "/faqs",
        },
        {
          title: t("footer.links.parentsGuide"),
          href: "/parents-guide",
        },
        {
          title: t("footer.links.apply"),
          href: "/apply",
        },
      ],
    },
    {
      title: t("footer.links.resourcesTitle"),
      links: [
        {
          title: t("footer.links.trainingMaterials"),
          href: "/training-materials",
        },
        {
          title: t("footer.links.calendar"),
          href: "/calendar",
        },
        {
          title: t("footer.links.gallery"),
          href: "/gallery",
        },
        {
          title: t("footer.links.news"),
          href: "/news",
        },
        {
          title: t("footer.links.downloads"),
          href: "/downloads",
        },
      ],
    },
    {
      title: t("footer.links.supportTitle"),
      links: [
        {
          title: t("footer.links.donate"),
          href: "/donate",
        },
        {
          title: t("footer.links.volunteer"),
          href: "/volunteer",
        },
        {
          title: t("footer.links.sponsorship"),
          href: "/sponsorship",
        },
        {
          title: t("footer.links.fundraising"),
          href: "/fundraising",
        },
        {
          title: t("footer.links.shop"),
          href: "/shop",
        },
      ],
    },
  ];

  const contentWithGutter = "max-w-[95%] lg:max-w-[90%] xl:max-w-[80%] 2xl:max-w-[1280px] w-full";
  return (
    <footer className="bg-white dark:bg-gray-900 bg-background-nav   text-text-white flex justify-center">
      <div className={`p-4 py-4 ${contentWithGutter}`}>
        <div className="flex justify-between md:flex-row flex-col-reverse gap-1 md:gap-2 hidden">
          <FooterLinks footerLinks={footerLinks} />
          <Newsletter />
        </div>
        <Divider className="hidden" />
        <div className="flex items-center justify-between">
          <Copyright />
          <div className="flex sm:justify-center sm:mt-0">
            <SocialLink href="https://www.facebook.com/101MonctonRCACS" icon={<FaFacebook />} srText="Facebook" />
            <SocialLink href="https://cjcr365.sharepoint.com/" icon={<FaMicrosoft />} srText="Cadet365" />
          </div>
        </div>
      </div>
    </footer>
  );
};
