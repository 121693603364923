import React, { useEffect, useState } from "react";
import { Navigate } from "react-router-dom";
import { Loading } from "../pages/Loading";

function ProtectedRoute({ children }) {
  const [isValidToken, setIsValidToken] = useState(true);

  useEffect(() => {
    const checkToken = async () => {
      const token = localStorage.getItem("token");

      try {
        const response = await fetch(`https://101-moncton-web-api.vercel.app/api/authenticate`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            "x-access-token": token,
            "access-control-allow-origin": "*",
          },
        });

        setIsValidToken(response.ok);
      } catch (error) {
        console.error("Error during token check", error);
      }
    };

    //checkToken();
  }, []);

  if (isValidToken === null) {
    return <Loading />;
  }

  return isValidToken ? children : <Navigate to="/login" />;
}

export default ProtectedRoute;
