import React from "react";
import { ThemeContext } from "../../contexts";

export const themeTailwindConfig = "transition ease-in-out bg-background dark:bg-background-dark text-text dark:text-text-dark duration-500";

export const ThemeToggle = () => {
  const { theme, setTheme } = React.useContext(ThemeContext);

  return (
    <div
      className="grid place-items-center text-center transition duration-500 ease-in-out rounded-full bg-gray-100 dark:bg-gray-700 cursor-pointer"
      onClick={() => setTheme(theme === "dark" ? "light" : "dark")}>
      {theme === "dark" ? <i className="text-text-white text-xl la la-moon" /> : <i className="text-text-white text-xl la la-sun" />}
    </div>
  );
};
