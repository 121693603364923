import React from "react";
import { SUPPORTED_LANGUAGES } from "../../../i18n";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../library/Dropdown";
import { FaGlobe } from "react-icons/fa6";

const left = "left-[2.5%] lg:left-[5%] xl:left-[10%] w-[47.5%] lg:w-[45%] xl:w-[40%]";

export const GliderBanner = () => {
  const { t, i18n } = useTranslation();
  const current_lang = i18n.resolvedLanguage;

  return (
    <div className={`w-full overflow-hidden relative max-h-[250px] grid place-content-center`}>
      <img className={`pt-16 xs:pt-64`} src="LocalAssets/GliderCover.jpg" />
    </div>
  );
};
