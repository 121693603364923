import React from "react";
import { SUPPORTED_LANGUAGES } from "../../i18n";
import { useTranslation } from "react-i18next";
import { Dropdown } from "./library/Dropdown";
import { FaGlobe } from "react-icons/fa6";

export const LocaleSwitcher = () => {
  const { t, i18n } = useTranslation();
  const current_lang = i18n.resolvedLanguage;

  return (
    <>
      <button
        className="min-w-[50px] grid place-items-center grid-flow-col"
        style={{
          fontWeight: "lighter",
        }}
        type="submit"
        onClick={() => i18n.changeLanguage(SUPPORTED_LANGUAGES[current_lang].nextLang)}>
        <FaGlobe className="inline m-auto" />
        <span className="inline min-w-[20px]">{SUPPORTED_LANGUAGES[current_lang].nativeName}</span>
      </button>
    </>
  );
};
