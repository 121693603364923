/** @type {import('tailwindcss').Config} */
const defaultTheme = require("tailwindcss/defaultTheme");

module.exports = {
  darkMode: "class",
  mode: "jit",
  content: ["./src/**/*.{js,jsx,ts,tsx}"],
  theme: {
    screens: {
      "3xs": "200px",
      "2xs": "350px",
      xs: "475px",
      ...defaultTheme.screens,
    },
    fontFamily: {
      display: ["Inter", "system-ui", "sans-serif"],
      body: ["Inter", "system-ui", "sans-serif"],
    },
    colors: {
      text: {
        DEFAULT: "#043771",
        dark: "#8EC1FB",
        white: "#FFF",
      },
      background: {
        DEFAULT: "#d8ebf8",
        dark: "#071B29",
        nav: "#093255",
      },
      primary: {
        DEFAULT: "#0E7AC8",
        dark: "#37A3F1",
      },
      accent: {
        DEFAULT: "#F2BE3A",
        dark: "#C5900D",
      },
      secondary: {
        DEFAULT: "#24E5FF",
        dark: "#00C2DB",
      },
      accent2: {
        DEFAULT: "#FF3C22",
        dark: "#DB1A00",
      },
      scroll: {
        dark: {
          track: "#04101a",
          thumb: "#0d477a",
          hover: "#06243d",
        },
        light: {
          track: "#d8ebf8",
          thumb: "#0E7AC8",
          hover: "#0d477a",
        },
      },
    },
    extend: {
      spacing: {
        "80%": "80%",
      },
    },
  },
  plugins: [],
};
