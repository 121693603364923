import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { Home, JoinPage, NotFound } from "./components/pages";
import { SiteSettingsProvider, ThemeProvider } from "./contexts";
import { LoginPage } from "./components/pages/login/LoginPage";
import "./i18n";
import { WebLayout } from "./components/common/layouts/WebLayout";
import { AboutPage } from "./components/pages/about/AboutPage";
import ProtectedRoute from "./components/authentication/ProtectedRoute";
import { themeTailwindConfig } from "./components/common/ThemeToggle";
import { MobileLayout } from "./components/common/layouts/MobileLayout";

function App() {
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(0);
  const [Layout, setLayout] = useState(() => WebLayout);

  useEffect(() => {
    setHeight(window.innerHeight);
    setWidth(window.innerWidth);
  });

  useEffect(() => {
    if (height < 700 || width < 820) {
      setLayout(() => {
        return MobileLayout;
      });
    } else {
      setLayout(() => {
        return WebLayout;
      });
    }
  }, [height]);

  return (
    <>
      {
        //<GoogleApiProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      }
      <ThemeProvider>
        <SiteSettingsProvider>
          <main className={`root ${themeTailwindConfig}`}>
            <BrowserRouter basename={""}>
              <Routes>
                <Route
                  path={"/"}
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <Home />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={"/join"}
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <JoinPage />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                <Route
                  path={"/about"}
                  element={
                    <ProtectedRoute>
                      <Layout>
                        <AboutPage />
                      </Layout>
                    </ProtectedRoute>
                  }
                />
                {/**
                <Route
                  path={"/login"}
                  element={
                    <>
                      <LoginPage />
                    </>
                  }
                /> */}
                <Route
                  path="*"
                  element={
                    <ProtectedRoute>
                      <NotFound />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </BrowserRouter>
          </main>
        </SiteSettingsProvider>
      </ThemeProvider>
      {
        //</GoogleApiProvider>
      }
    </>
  );
}

export default App;
