import { ThemeToggle, Header, Footer } from "../../common";

import React from "react";
import { JoinBanner } from "../../common/banners/JoinBanner";
import { FaBook, FaCalendar, FaHandshakeSimple, FaHandsHolding, FaJediOrder, FaUserGroup } from "react-icons/fa6";
import { themeTailwindConfig } from "../../common/ThemeToggle";
import { CalendarComponent } from "../../common/UpcomingEvents";
import { Panel, ReactiveGutter, ActionBox } from "../../common/AssortedComponents";
import { NavBarSpacer } from "../../common/layouts/WebLayout";

export const JoinPage = () => {
  return (
    <div className={`flex-grow place-content-center`}>
      <div className="hidden xs:grid">
        <NavBarSpacer />
      </div>
      <div>
        <Panel>
          <JoinBanner />
        </Panel>
      </div>
    </div>
  );
};
