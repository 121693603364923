import React from "react";
import { CalendarComponent } from "../../common/UpcomingEvents";
import { FullScreenPanel, Panel, ReactiveGutter, ActionBox } from "../../common/AssortedComponents";
import { RCACSBanner } from "../../common/banners/RCACSBanner";
import { NavBarSpacer } from "../../common/layouts/WebLayout";
import { useLocation } from "react-router-dom";

export const Home = () => {
  const location = useLocation();
  return (
    <>
      <div className={`flex-grow place-content-center overflow-hidden`}>
        {location.pathname === "/" ?? <NavBarSpacer />}
        <FullScreenPanel className="h-full overflow-hidden" hideChevron>
          <RCACSBanner />
        </FullScreenPanel>
        <Panel className="hidden">
          <ReactiveGutter>
            <CalendarComponent />
          </ReactiveGutter>
        </Panel>
        <Panel className="hidden">
          <ReactiveGutter>
            <ActionBox />
          </ReactiveGutter>
        </Panel>
      </div>
    </>
  );
};
