import React from "react";
import tw_theme from "../tailwind-theme";

const getInitialTheme = () => {
  if (typeof window !== "undefined" && window.localStorage) {
    const storedTheme = window.localStorage.getItem("wallet-theme");
    if (typeof storedTheme === "string") {
      return storedTheme;
    }

    const userMedia = window.matchMedia("(prefers-color-scheme: dark)");
    if (userMedia.matches) {
      return "dark";
    }
  }

  return "light"; // light theme as the default;
};

export const ThemeContext = React.createContext(null as any);

export const ThemeProvider = ({ initialTheme, children }: { initialTheme?: string; children: React.ReactNode }) => {
  const [theme, setTheme] = React.useState(getInitialTheme);

  const rawSetTheme = (rawTheme: any) => {
    const root = window.document.documentElement;
    const isDark = rawTheme === "dark";

    root.classList.remove(isDark ? "light" : "dark");
    root.classList.add(rawTheme);

    localStorage.setItem("wallet-theme", rawTheme);
  };

  if (initialTheme) {
    rawSetTheme(initialTheme);
  }

  React.useEffect(() => {
    rawSetTheme(theme);
  }, [theme]);

  console.log(tw_theme.colors);

  return (
    <ThemeContext.Provider value={{ theme, setTheme }}>
      <style>
        {`
          ::-webkit-scrollbar {
            width: 10px;
          }
          
          /* Track */
          ::-webkit-scrollbar-track {
            background: ${tw_theme.colors.scroll[theme].track}; 
          }
           
          /* Handle */
          ::-webkit-scrollbar-thumb {
            background: ${tw_theme.colors.scroll[theme].thumb}; 
          }
          
          /* Handle on hover */
          ::-webkit-scrollbar-thumb:hover {
            background: ${tw_theme.colors.scroll[theme].hover}; 
          }
        `}
      </style>
      {children}
    </ThemeContext.Provider>
  );
};
