import React, { useState, useEffect } from "react";
import { Panel, ReactiveGutter, ActionBox } from "../../common/AssortedComponents";
import { GliderBanner } from "../../common/banners/GliderBanner";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

export const StaffCard = ({ img, title, subTitle, content }) => {
  return (
    <div className="w-full h-3/4 flex mx-[10%] align-middle p-2">
      <img className="w-1/2" src={img} />
      <div className="p-8 content-center">
        <h1 className="dark:text-accent font-bold text-center text-xl xs:text-3xl">{title}</h1>
        <h2 className="text-l xs:text-xl font-bold text-center">{subTitle}</h2>
        <span className="hidden whitespace-pre-line">{content}</span>
      </div>
    </div>
  );
};

const Banner = ({ title, className, accent }) => {
  return (
    <Panel>
      <div className={`w-full h-28 ${className} ${accent ? "bg-accent dark:bg-accent-dark" : "bg-primary dark:bg-primary-dark"}`}>
        <div className={`relative top-[50%] translate-y-[-50%]`}>
          <h1 className="text-6xl text-background-dark  text-center font-thin">{title}</h1>
        </div>
      </div>
    </Panel>
  );
};

export const AboutPage = () => {
  const { t } = useTranslation();
  return (
    <div className="pb-2 overflow-hidden">
      <Panel>
        <GliderBanner />
      </Panel>
      <Banner title={t("aboutPage.aboutUs")} />
      <Panel marginY={8}>
        <ReactiveGutter>
          <div className={`w-full mx-[5%] sm:mx-[10%] my-14`}>
            <span className="whitespace-pre-line inline-block text-xl text-justify sm:text-left">
              <b>{t("aboutPage.squadronName")}</b> {t("aboutPage.aboutUsContent")}
            </span>
          </div>
        </ReactiveGutter>
      </Panel>
      <Banner title={t("aboutPage.proudlyTraining")} className={"h-96 2xs:h-80 xs:h-48 sm:h-40 xl:h-28"} accent />
      <Panel>
        <div className="w-full h-3/4 flex overflow-hidden">
          <img className="w-1/4" src="LocalAssets/SummerTraining2.jpg" />
          <img className="w-1/2" src="LocalAssets/SquadronGroupPhoto.jpg" />
          <Link to={"/join"} className="w-1/4 hover:scale-105 hover:cursor-pointer transition-all duration-300">
            <img src="LocalAssets/Join101.png" />
          </Link>
        </div>
      </Panel>
      <Banner title={t("aboutPage.history.title")} />
      <Panel>
        <ReactiveGutter>
          <div className="w-full mx-[5%] sm:mx-[10%] my-14">
            <span className="whitespace-pre-line inline-block text-xl text-justify sm:text-left">{t("aboutPage.history.content")}</span>
          </div>
        </ReactiveGutter>
      </Panel>
      <Banner title={t("aboutPage.ourStaff")} />
      <Panel>
        <ReactiveGutter>
          <StaffCard
            img="LocalAssets/commandingOfficer.PNG"
            title={t("aboutPage.staff.coHighlight.title")}
            subTitle={t("aboutPage.staff.coHighlight.subTitle")}
            content={t("aboutPage.staff.coHighlight.content")}
          />
        </ReactiveGutter>
      </Panel>
      <Link aria-current="page" to={"/join"} className="hidden">
        <Banner title={t("aboutPage.register")} accent />
      </Link>
    </div>
  );
};
