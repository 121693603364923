import React from "react";
import { SUPPORTED_LANGUAGES } from "../../../i18n";
import { useTranslation } from "react-i18next";
import { Dropdown } from "../library/Dropdown";
import { FaGlobe } from "react-icons/fa6";
import { Link } from "react-router-dom";

const left = "left-[2.5%] lg:left-[5%] xl:left-[10%] w-[47.5%] lg:w-[45%] xl:w-[40%]";
const media = "max-w-[300px] md:max-w-[450px] lg:max-w-[550px]";

export const RCACSBanner = () => {
  const { t, i18n } = useTranslation("", { keyPrefix: "homePage" });
  const current_lang = i18n.resolvedLanguage;

  return (
    <>
      <div className={`hidden md:grid fixed h-full left-0 top-0 overflow-hidden place-content-center w-[100vw] ${media}`}>
        <div className={`relative outline-text font-bold left-0 top-0 grid place-items-center`}>
          <div className={`absolute z-10 grid gap-1 ${media}`}>
            <div className={`${media} h-[255px]  ${current_lang === "fr" ? "lg" : "md"}:h-[230px]  bg-accent dark:bg-accent-dark`}>
              <h1 className="font-bold text-9xl text-right text-text-white">101</h1>
              <div className={`px-4`}>
                <p className="text-5xl text-right text-text-white">Moncton</p>
                <p className="text-3xl pt-1 text-right text-text-white">{t("rcac")}</p>
              </div>
            </div>
            <div className={`outline-text w-[100vw] ${media} h-[50px]  top-[255px] flex flex-row-reverse gap-2`}>
              <button className={`w-auto h-[50px]`}>
                <Link aria-current="page" to={"/join"}>
                  <p className="outline-text text-4xl h-[50px] p-2 py-1 pl-3 text-right text-text-white bg-accent dark:bg-accent-dark hover:bg-accent-dark dark:hover:bg-accent transition-all duration-300">
                    {t("joinUs")}
                  </p>
                </Link>
              </button>
              <button className={"w-auto h-[50px]"}>
                <Link aria-current="page" to={"/about"}>
                  <p className="place-content-center h-[50px] text-2xl p-2 py-1 pl-3 text-right text-text-dark dark:text-text hover:text-text dark:hover:text-text-dark transition-all duration-300">
                    {t("learnMore")}
                  </p>
                </Link>
              </button>
            </div>
          </div>
        </div>
        <div className={`fixed h-full right-0 top-0 self-center overflow-hidden grid place-content-center`}>
          <img className={`min-w-[60vw]`} src="LocalAssets/B&W-Salute4.png" alt="FFFFF" />
        </div>
      </div>
      <div className={`relative grid top-12 md:hidden h-full right-0 place-content-center w-[100vw]`}>
        <Link aria-current="page" to={"/join"}>
          <img className={`min-w-[100vw]`} src="LocalAssets/JOIN101_NEW.png" alt="FFFFF" />
        </Link>
      </div>
    </>
  );
};
