import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

export const SUPPORTED_LANGUAGES = {
  en: { nativeName: "En", nextLang: "fr" },
  fr: { nativeName: "Fr", nextLang: "en" },
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "en",
    interpolation: {
      escapeValue: true,
    },
    resources: {
      en: {
        translation: {
          menu: {
            rcacs: "RCACS",
            home: "Home",
            about: "About",
            join: "Join",
            login: "Login",
          },
          footer: {
            copyright: {
              rcacs: "Royal Canadian Air Cadet Squadron",
              allRightsReserved: "All rights reserved",
            },
            newsletter: {
              subscribeToOurNewsletter: "Join our newsletter to stay up to date on regular training and extra curricular activities",
              subscribe: "Subscribe",
              rcacs: "RCACS",
              nb: "New Brunswick",
              emailPlaceholder: "Your Email",
            },
            links: {
              aboutTitle: "About",
              joinTitle: "Join",
              resourcesTitle: "Resources",
              supportTitle: "Support",
              whoWeAre: "Who We Are",
              history: "History",
              mission: "Mission",
              contact: "Contact",
              privacy: "Privacy",
              join: "Join",
              benefits: "Benefits",
              faq: "FAQ",
              parentsGuide: "Parents Guide",
              apply: "Apply",
              trainingMaterials: "Training Materials",
              calendar: "Calendar",
              gallery: "Gallery",
              news: "News",
              downloads: "Downloads",
              donate: "Donate",
              volunteer: "Volunteer",
              sponsorship: "Sponsorship",
              fundraising: "Fundraising",
              shop: "Shop",
            },
          },
          homePage: {
            rcac: "Royal Canadian Air Cadets",
            learnMore: "Learn More",
            joinUs: "Join Us",
          },
          aboutPage: {
            aboutUs: "About Us",
            squadronName: "101 Moncton Royal Canadian Air Cadets",
            aboutUsContent: `trains on Friday nights at the Moncton Garrison. The squadron also has a drill team, a marksmanship team, a biathlon team, an effective speaking program, a cadet correspondent team, and a brass and reed band. Opportunities for summer training, flying, and field training exercises also happen over the course of the year. Currently the squadron’s Commanding Officer is Captain Stephany Ried and the Squadron Commander is Warrant Officer First Class Kylie Blackman.`,
            proudlyTraining: "Proudly Training Moncton Youth Since 1942",
            history: {
              title: "History",
              content: `The 101 Moncton High School Squadron was formed on March 4, 1942. Squadron Leader B. O. Lounder was first Commanding Officer. The squadron’s first sponsor was Moncton Legion (branch #6). In 1952, No. 253 Wing Royal Canadian Air Force Association became the squadron sponsor. It was in 1966 that the squadron began training at the Moncton Garrison and took High School out of our name. In 1968 Moncton flying club became the squadron’s cosponsor. In 1972 Ladies Auxiliary No. 253 funded the purchase of uniforms for “Girl Flight” in order to  allow young girls to join 101 Moncton. 1975 to 1976 101 was the top squadron in New Brunswick.`,
              content2:
                "In 1972 Ladies Auxiliary No. 253 funded the purchase of uniforms for “Girl Flight” in order to  allow young girls to join 101 Moncton. 1975 to 1976 101 was the top squadron in New Brunswick.",
            },
            staff: {
              coHighlight: {
                title: "Capt. Reid",
                subTitle: "Commanding Officer",
                content:
                  "I am looking forward to working with our cadets, staff, and support committee to continue to keep 101 Moncton as one of the most active youth organizations in Moncton and surrounding counties, while keeping our youth learning, engaging, and interacting with their community and environment. \n\n Our goals within the cadet program are to develop attributes of citizenship, leadership, and a healthy lifestyle, none of which are strangers to 101 Moncton. 101 Moncton will soon be entering its 82nd year of service to our community’s youth. \n\nThis long history of service could not have been accomplished without the support of our community partners and supporters: The Air Cadet League of Canada. I look forward to seeing you all at regular traiing where we will continue to offer fun, engaging, safe, and meaningful activities for our cadets.",
              },
            },
            ourStaff: "Our Staff",
            register: "Register For Free Today",
          },
          joinPage: {
            title: "Joining 101 is simple!",
            step: "Step",
            steps: [
              "Use the button below to fill out the online application. Please ensure you attach all documents required.",
              "Wait for the email telling you when to arrive for your first night.",
              "You will attend a five-week recruit course teaching you how to be a cadet.",
              "You will then be sorted into your flight and soon get a uniform!",
            ],
            button: "Get Started!",
          },
        },
      },
      fr: {
        translation: {
          menu: {
            rcacs: "ECARC",
            home: "Acceuil",
            about: "À propos",
            join: "Joindre",
            login: "Se Connecter",
          },
          footer: {
            copyright: {
              rcacs: "Escadron des Cadets de l'Aviation Royale Canadienne",
              allRightsReserved: "Tous droits réservés",
            },
            newsletter: {
              subscribeToOurNewsletter: "Joignez notre bulletin pour rester à jour sur les activités de formation régulières et extra-scolaires",
              subscribe: "S'inscrire",
              rcacs: "RCACS",
              nb: "Nouveau-Brunswick",
              emailPlaceholder: "Votre Courriel",
            },
            links: {
              aboutTitle: "À Propos",
              joinTitle: "Joindre",
              resourcesTitle: "Ressources",
              supportTitle: "Soutien",
              whoWeAre: "Qui Nous Sommes",
              history: "Histoire",
              mission: "Mission",
              contact: "Contact",
              privacy: "Vie Privée",
              join: "Joindre",
              benefits: "Avantages",
              faq: "FAQ",
              parentsGuide: "Guide des Parents",
              apply: "Appliquer",
              trainingMaterials: "Matériaux de Formation",
              calendar: "Calendrier",
              gallery: "Galerie",
              news: "Nouvelles",
              downloads: "Téléchargements",
              donate: "Faire un Don",
              volunteer: "Bénévole",
              sponsorship: "Parrainage",
              fundraising: "Collecte de Fonds",
              shop: "Boutique",
            },
          },
          homePage: {
            rcac: "Cadets de l'Air Royale Canadienne",
            learnMore: "Savoir",
            joinUs: "Joindre",
          },
          aboutPage: {
            aboutUs: "À Propos",
            squadronName: "101 Moncton Escadron des Cadets de l'Aviation Royale Canadienne",
            aboutUsContent: `s'entraîne les vendredis soirs à la garnison de Moncton. L'escadron dispose également d'une équipe de marche militaire, d'une équipe de tir, d'une équipe de biathlon, d'un programme d'art oratoire, d'une équipe de cadets correspondants et d'une fanfare de cuivres et de bois. Des opportunités de formation estivale, de vol et d'exercices d'entraînement sur le terrain se produisent également au cours de l'année. Actuellement, l'officier commandant de l'escadron est la capitaine Stephany Ried et la commandant de l'escadron est l'adjudante de première classe Kylie Blackman.`,
            proudlyTraining: "Formation des Jeunes de Moncton Depuis 1942",
            history: {
              title: "Histoire",
              content: `L'escadron 101 de l'école secondaire Moncton a été formé le 4 mars 1942. Le chef d'escadron B. O. Lounder était le premier commandant. Le premier parrain de l'escadron était la Légion de Moncton (section #6). En 1952, l'Association de l'Aviation Royale Canadienne, No. 253 Wing, est devenue le parrain de l'escadron. C'est en 1966 que l'escadron a commencé à s'entraîner à la garnison de Moncton et a retiré l'école secondaire de son nom. En 1968, le club de vol de Moncton est devenu le co-parrain de l'escadron. En 1972, l'auxiliaire des dames No. 253 a financé l'achat d'uniformes pour «Girl Flight» afin de permettre aux jeunes filles de rejoindre 101 Moncton. De 1975 à 1976, 101 était le meilleur escadron du Nouveau-Brunswick.`,
            },
            staff: {
              coHighlight: {
                title: "Capt Reid",
                subTitle: "Officier Commandante",
                content:
                  "Je suis impatient de travailler avec nos cadets, notre personnel et notre comité de soutien pour continuer à maintenir 101 Moncton comme l'une des organisations de jeunes les plus actives de Moncton et des comtés environnants, tout en permettant à nos jeunes d'apprendre, de s'engager et d'interagir avec leur communauté et leur environnement. \n\n Nos objectifs au sein du programme des cadets sont de développer les attributs de citoyenneté, de leadership et un mode de vie sain, qui ne sont pas étrangers à 101 Moncton. 101 Moncton s'apprête à entrer dans sa 82e année de service à la jeunesse de son communauté. \n\n Cette longue histoire de service n'aurait pas pu être accomplie sans le soutien de nos partenaires et soutiens communautaires: La Ligue des Cadets de l'Air du Canada. J'ai hâte de vous voir tous à l'entraînement régulier où nous continuerons à offrir des activités amusantes, engageantes, sûres et significatives pour nos cadets.",
              },
            },
            ourStaff: "Notre Personnel",
            register: "Inscrivez-vous gratuitement aujourd'hui",
          },
          joinPage: {
            title: "Rejoindre 101 est simple!",
            step: "Étape",
            steps: [
              "Utilisez le bouton ci-dessous pour remplir la demande en ligne. Veuillez vous assurer de joindre tous les documents requis.",
              "Attendez l'e-mail vous indiquant quand arriver pour votre première nuit.",
              "Vous suivrez un cours de recrue de cinq semaines vous apprenant à être un cadet.",
              "Vous serez ensuite trié dans votre vol et obtiendrez bientôt un uniforme!",
            ],
            button: "Commencer!",
          },
        },
      },
    },
  });

export default i18n;
